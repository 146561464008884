<template>
  <div id="approveList">
    <bhNavBar :NoleftArrow="true" title="审批列表"></bhNavBar>
    <div class="body_contract">
      <van-pull-refresh v-model="refreshing"  @refresh="onRefresh" >
        <van-list v-model="loading" style="margin-top: 10px" :finished="finished" :finished-text="finished ? '' : '已经到底了'" @load="this.getList">
          <div class="review" v-for="(item,index) in tableData" :key="index">
            <div class="top-title" @click="jumpDetails(item.businessId, item)">未审批</div>
            <div class="position" @click="jumpDetails(item.businessId, item)">{{ item.approveName }}</div>
            <div class="details" @click="jumpDetails(item.businessId, item)">
              <div class="info">
                <div class="order">提交人</div>
                <div class="order-name">{{ item.applyUserName }}</div>
              </div>
              <div class="line"></div>
              <div class="info">
                <div class="order">提交时间</div>
                <div class="order-name">{{ item.applyDate }}</div>
              </div>
            </div>
            <div class="bottom">
              <div class="check cancleButton" @click="cancleButton(item)">驳回</div>
              <div class="check saveButton" @click="saveButton(item)">通过</div>
            </div>
          </div>
          <common-empty v-if="isEmptuyFlag"></common-empty>
        </van-list>
      </van-pull-refresh>
    </div>
    <!-- 通过审核 -->
    <van-popup v-model="adoptPopup" position="bottom">
      <div class="popup_wrapper esc">
        <p class="popup_title" > <span class="colon_mes">确认同意该审批</span> </p>
        <textarea placeholder="请输入通过原因" v-model="rejectRemark" class="remake"/>
        <div class="sub_btn">
          <van-button class="sub_button" size="large" @click="adoptOffer()">确定</van-button>
        </div>
      </div>
    </van-popup>
    <!--    驳回-->
    <van-popup v-model="canclePopup" position="bottom">
      <div class="popup_wrapper esc">
        <textarea placeholder="请输入驳回原因" v-model="rejectRemark" class="remake"/>
        <div class="sub_btn">
          <van-button class="sub_button" size="large" @click="cancleOffer()">确定</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {NavBar, Icon, Cell, CellGroup, Button, Col, Row, List, PullRefresh, Popup, Toast} from 'vant';
import bhNavBar from "@/components/bright/bh-navBar/navBar";
import {checkToken} from "@/libs/util";
import {responseUtil} from "@/libs/brightUtil";
import {myApprove, myApproveList, passAudit, overrule, clueTransfer, znPositionApprovePass, znPositionApproveReject } from "@/getData/getData";
export default {
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Col.name]: Col,
    [Row.name]: Row,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Popup.name]: Popup,
    [Toast.name]: Toast,
    bhNavBar
  },
  name: "approveList",
  data() {
    return {
      isLoading: false,
      isEmptuyFlag: false,
      finished: false,
      refreshing:false,
      loading:false,
      adoptPopup:false,
      canclePopup:false,
      popupData: {},
      rejectRemark: '',
      retParams:{ type:true },
      page: {
        pageSize: 10,
        totalCount: 0,
        pageNum: 1
      },
      tableData: []
    }
  },
  methods: {
    async getList() {
      var that = this
      let initData = {}
      initData = {...this.page}
      var checkTokenType = await checkToken()
      if (checkTokenType == false || checkTokenType == 'false') {
        this.$router.replace({name: 'login'})
        return
      }
      myApproveList(initData).then(function (res) {
        responseUtil.dealResponse(that, res, () => {
          that.page.totalCount = res.data.total
          let list = res.data.records
          for (let i = 0; i < list.length; i++) {
            that.tableData.push(list[i])
          }
          // 加载状态结束
          that.loading = false;
          if (that.refreshing) {
            that.refreshing = false
          }
          // 数据全部加载完成
          if (that.tableData.length >= that.page.totalCount) {
            that.finished = true;
          }
          if (that.tableData.length == 0) {
            that.isEmptuyFlag = true
          } else {
            that.isEmptuyFlag = false
          }
          that.page.pageNum++;
        })
      })
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.refreshing = false
      this.tableData = []
      this.page = {pageSize: 10, totalCount: 0, pageNum: 1},
      this.getList();
    },
    saveButton(data) {
      this.rejectRemark = ''
      this.adoptPopup = true
      this.popupData = data
    },
    cancleButton(data) {
      this.rejectRemark = ''
      this.canclePopup = true
      this.popupData = data
    },
    adoptOffer() {
      var that = this
      const param = {
        approve_number: this.popupData.settingApproveNumber,
        approveType: 1,
        approveRemark: that.rejectRemark,
        creater: this.popupData.applyUserId,
        approveId: this.popupData.approveId,
        business_id: this.popupData.businessId
      }
      if (this.popupData.settingApproveNumber === 'BH00004') {
        myApprove(param).then((res) => {
          responseUtil.dealResponse(that, res, () => {
            responseUtil.alertOK('审批已通过')
            this.adoptPopup = false
            this.onRefresh()
          })
        })
      } else if (this.popupData.settingApproveNumber === 'BH00005') {
        passAudit(this.popupData.approveId).then(res => {
          responseUtil.dealResponse(that, res, () => {
            responseUtil.alertOK('审批已通过')
            this.adoptPopup = false
            this.onRefresh()
          })
        })
      } else if (this.popupData.settingApproveNumber === 'BH00006') {
        clueTransfer(this.popupData.approveId).then(res => {
          responseUtil.dealResponse(that, res, () => {
            responseUtil.alertOK('审批已通过')
            this.adoptPopup = false
            this.onRefresh()
          })
        })
      } else if (this.popupData.settingApproveNumber === 'BH00007') {
        znPositionApprovePass(param).then(res => {
          responseUtil.dealResponse(that, res, () => {
            responseUtil.alertOK('审批已通过')
            this.adoptPopup = false
            this.onRefresh()
          })
        })
      }
    },
    cancleOffer(){
      var that = this
      if(that.rejectRemark == '' || that.rejectRemark == undefined){
        responseUtil.alertMsg('请输入驳回原因')
        return
      }
      const param = {
        approve_number: that.popupData.settingApproveNumber,
        approveType: 2,
        approveRemark: that.rejectRemark,
        creater: that.popupData.applyUserId,
        approveId: that.popupData.approveId,
        business_id: that.popupData.businessId
      }
      if (that.popupData.settingApproveNumber === 'BH00004') {
        myApprove(param).then((res) => {
          responseUtil.dealResponse(that, res, () => {
            responseUtil.alertOK('审批已驳回')
            this.canclePopup = false
            this.$nextTick(() => {
              this.rejectRemark = ''
            })
            this.onRefresh()
          })
        })
      } else if (that.popupData.settingApproveNumber === 'BH00005' || that.popupData.settingApproveNumber === 'BH00006') {
        overrule(param).then(res => {
          responseUtil.dealResponse(that, res, () => {
            responseUtil.alertOK('审批已驳回')
            this.canclePopup = false
            this.$nextTick(() => {
              this.rejectRemark = ''
            })
            this.onRefresh()
          })
        })
      } else if (that.popupData.settingApproveNumber === 'BH00005' || that.popupData.settingApproveNumber === 'BH00007') {
        znPositionApproveReject(param).then(res => {
          responseUtil.dealResponse(that, res, () => {
            responseUtil.alertOK('审批已驳回')
            this.canclePopup = false
            this.$nextTick(() => {
              this.rejectRemark = ''
            })
            this.onRefresh()
          })
        })
      }
    },
    jumpDetails(businessId, item) {
      if (item.settingApproveNumber === 'BH00005') {
        this.$router.push({ name: 'clueApproveDetail' , query: { id: item.approveId }})
      } else if (item.settingApproveNumber === 'BH00006') {
        this.$router.push({ name: 'clueMoveApproveDetail' , query: { id: item.approveId }})
      } else if (item.settingApproveNumber === 'BH00007') {
        this.$router.push({ name: 'znPartTimePositionApproveDetails' , query: { id: item.businessId }})
      } else {
        this.$router.push({ name: 'approveDetails' , query: { id: businessId }})
      }
    },
    //返回上一层
    leftReturn() {
      this.$router.go(-1);
    }
  },
}
</script>

<style lang="less" scoped >
#approveList{
  .body_contract{
    height: calc(100vh - 1.5rem);
  }
  .top-title {
    margin: 0;
    width: fit-content;
    padding: 0 5px;
    height: 20px;
    font-size: 10px;
    text-align: center;
    line-height: 22px;
    border-radius: 8px 0;
    background: linear-gradient(90deg, #1500FB 0%, #5400FB 47%, #6D00FB 99%);
    color: white;
  }
  input {
    font-size: 14px;
    margin-left: 10px;
    background-color: rgba(244, 244, 244, 1);
    border: 0;
    width: 250px;
  }
  input::-webkit-input-placeholder {
    color: rgba(199, 199, 199, 1);
  }
  .review {
    margin: 0 15px 15px 15px;
    height: auto;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
  }

  .position {
    margin: 15px 15px 0 15px;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    text-align: left;
    color: black;
    font-weight: bold;
    overflow: hidden; /*超出部分隐藏*/
    white-space: nowrap; /*不换行*/
    text-overflow: ellipsis; /*超出部分文字以...显示*/
  }

  .details {
    margin-top: 10px;
    width: 100%;
    height: auto;
    border-bottom: 1px solid #f4f4f4;
    display: flex;
    justify-content: space-between;
  }

  .details .info {
    width: 180px;
    height: auto;
    text-align: center;
    margin-top: 10px;
  }

  .details .info .order {
    width: 100%;
    height: 20px;
    font-size: 14px;
    line-height: 20px;
    color: #999999;
  }

  .details .info .order-name {
    width: 100%;
    height: 20px;
    font-size: 14px;
    line-height: 20px;
    color: #fe5e3a;
    margin-top: 10px;
    margin-bottom: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: bold;
  }

  .details .line {
    width: 1px;
    height: 40px;
    background-color: #f4f4f4;
    margin-top: 18px;
  }

  .bottom {
    width: 95%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .bottom .time {
    width: 180px;
    height: 20px;
    text-align: left;
    line-height: 20px;
    font-size: 14px;
    color: #999999;
    margin-left: 15px;
  }

  .bottom .check {
    width: 90px;
    height: 32px;
    font-size: 14px;
    text-align: center;
    line-height: 35px;
    margin-left: 15px;
    border-radius: 8px;
  }
  .cancleButton {
    color: black;
    border: 1px solid black;
  }
  .saveButton{
    color: white;
    background: linear-gradient(90deg, #1500FB 0%, #5400FB 47%, #6D00FB 99%);
  }

  .popup_wrapper {
    width: 345px;
    padding: 15px;
    background-color: rgb(250, 250, 250);
    &.esc {
      background-color: rgb(255, 255, 255);
    }
    .popup_title {
      width: 150px;
      font-size: 16px;
      font-weight: bold;
      margin: 20px 0;
    }
    .sub_btn {
      margin-top: 50px;
      .sub_button {
        border-radius: 8px;
        color: rgb(255, 255, 255);
        border: none;
        background: linear-gradient(90deg, #1500FB 0%, #5400FB 47%, #6D00FB 99%);
      }
    }
    /*备注输入框*/
    .remake{
      color: gray;
      width: 310px;
      border-radius: 10px;
      line-height: 20px;
      height: 70px;
      margin: 5px 10px 0 10px;
      background-color: #f8f8f8;
      font-size: 12px;
      border: none;
      padding-left: 10px;
      padding-top: 10px;
    }
  }
}
</style>
